* {
  margin: 0;
  padding: 0;
}

body, button, input {
  font-family: Helvetica, Arial, sans-serif;
}

body {
  min-height: 100vh;
  flex-direction: column;
  align-items: center;
  display: flex;
}

main {
  box-sizing: border-box;
  width: 50rem;
  max-width: 100vw;
  flex-direction: column;
  gap: 2rem;
  margin: auto 0;
  padding: 1rem;
  display: flex;
}

/*# sourceMappingURL=index.6cf64995.css.map */
