* {
  margin: 0;
  padding: 0;
}

body,
button,
input {
  font-family: 'Helvetica', 'Arial', sans-serif;
}

body {
  display: flex;
  flex-direction: column;
  /* justify-content: center; */
  align-items: center;
  min-height: 100vh;
}

main {
  box-sizing: border-box;
  margin: auto 0;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  width: 50rem;
  max-width: 100vw;
  padding: 1rem;
}
